<template>
    <div>
       
        <test1  v-bind:user="user1">
            {{user1.lastName}}
          
        </test1>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Test1 from "./Test1";
    export default {
        name: "bom",
        extends: ax.control(),
        data() {
            return {
                user:{lastName:"abcd"}
                
            }
        },
        mounted() {
          
        },
        methods: {
           
        },
        components:{
            "test1":Test1
        }
    }
</script>
<style scoped>
    .el-header,
    .el-footer {
        /* background-color: #b3c0d1; */
        color: #333;
        /* text-align: center; */
        /* line-height: 20px; */
    }

    .el-main {
        background-color: #e9eef3;
        color: #333;
        /* text-align: center; */
        line-height: 400px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }
</style>